<template>
  <div class="head-container">
    <!-- 搜索 -->
    <search-community ref="searchcommunity"></search-community>
    <el-input v-model="query.signalInfo" clearable placeholder="输入信号源昵称/MT4" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-input v-model="query.copyInfo" clearable placeholder="输入订阅者昵称/MT4" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-input v-model="query.id" clearable placeholder="编号" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-select v-model="query.status" clearable placeholder="订阅状态" class="filter-item" style="width: 130px" @change="toQuery">
      <el-option v-for="item in queryStatusOptions" :key="item.key" :label="item.display_name" :value="item.key"/>
    </el-select>
    &nbsp;&nbsp;
    <div class="filter-item">
      到期时间:
      <el-date-picker style="width: 150px;" v-model="query.beginDate" type="date" placeholder="开始时间"></el-date-picker>
      至
      <el-date-picker style="width: 150px;" v-model="query.expireDate" type="date" placeholder="结束时间"></el-date-picker>
    </div>
    &nbsp;&nbsp;
    <el-button
      v-if="checkPermission(['ADMIN','SUBSCRIPTION_ALL','SUBSCRIPTION_SELECT'])"
      class="filter-item"
      size="mini"
      type="primary"
      icon="el-icon-search"
      @click="toQuery"
    >搜索</el-button>
    <el-button class="filter-item" size="mini" @click="toClearInit">清空</el-button>
  </div>
</template>

<script>
  import searchCommunity from '@/components/common/searchCommunity'
  // copySiteId;  //跟随者站点id
  // copyName;  //跟随者名称
  // copyLogin; //跟随者MT4账号
  // signalName; //交易员名称
  // signalLogin;  //交易员MT4账号
  // status; //0-有效 1-失效 2-取消订阅 3-通讯失败
  // beginDate; //查询开始时间
  // expireDate; //查询结束时间
import checkPermission from '@/utils/permission' // 权限判断函数
import { parseTime } from '@/utils/index'
export default {
  components: {
    searchCommunity,
  },
  props: {
    query: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      downloadLoading: false,
      queryStatusOptions: [
        { key: '0', display_name: '有效' },
        { key: '1', display_name: '已过期' },
        { key: '2', display_name: '取消订阅'},
        { key: '3', display_name: '失败' },
        { key: '4', display_name: '待上传凭证'},
        { key: '5', display_name: '待确认' },
        { key: '6', display_name: '已取消' },
        { key: '7', display_name: '已投诉' },
        { key: '8', display_name: '已拒绝' },
      ],
    }
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$parent.page = 0
      this.$parent.init()
    },
    toClearInit(){
      this.$parent.toClearInit()
    },
  }
}
</script>
