<template>
  <div class="app-container">
    <eHeader ref="header" :query="query"/>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="id" label="编号" width="80"/>
      <el-table-column prop="siteName" label="社区名称"/>
      <el-table-column prop="copyLogin" label="订阅者MT4"/>
      <el-table-column prop="copyName" label="订阅者昵称"/>
      <el-table-column prop="signalLogin" label="信号源MT4">
        <template slot-scope="scope">
          <span>{{scope.row.signalLogin || '-'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="signalName" label="信号源昵称">
        <template slot-scope="scope">
          <span>{{scope.row.signalName || '-'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="signalType" label="信号类型"/>
      <el-table-column prop="subscribePrice" label="费用">
        <template slot-scope="scope">
          <span
            class="price"
            :class="{
              active: getActive(scope.row),
              pointer: getActive(scope.row)
            }"
            @click="priceHandle(scope.row)">
            {{scope.row.subscribePrice}}
            <span v-if="getUsdtFlag(scope.row)">USDT</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="payChannel" label="支付渠道">
         <template slot-scope="scope">
           <span>{{getPayChannel(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="payCurrency" label="支付币种"/>
      <el-table-column prop="payAmount" label="支付金额"/>
      <el-table-column prop="beginDate" label="提交时间">
        <template slot-scope="scope">
          <span>{{parseTime(scope.row.beginDate)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <span>{{getStatusSelf(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="平仓方式">
        <template slot-scope="scope">
          <span>{{getType(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180px" align="center">
        <template slot-scope="scope">
          <viewDetail class="my-button" :data="scope.row" :sup_this="sup_this" :isCheck="false"/>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { parseTime } from '@/utils/index'
import { getSitesData } from '@/api/cms/srSite'
import eHeader from '@/components/community/subscription/subflow/header'
import viewDetail from '@/components/community/subscription/subflow/view'
export default {
  name:'subflow',
  components: { eHeader,viewDetail },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      sup_this: this,
      dialogFormVisible:false,
      flatformdata:[],
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = '/community/crm/srCommunitySubscription/getSerials'
      const sort = 'ib.id,desc'
      const query = this.query;

      this.params = {
        page: this.page,
        size: this.size,
        copyInfo: query.copyInfo,
        signalInfo: query.signalInfo,
        id: query.id,
        status: query.status,
        beginDate: parseTime(query.beginDate),
        expireDate: parseTime(query.expireDate),
        sort: sort,
      }
      if (this.$refs.header.$refs.searchcommunity.siteId) {
          this.params['siteId'] = this.$refs.header.$refs.searchcommunity.siteId
      }
      return true
    },
    toClearInit() {
      this.query = {}
      this.init()
    },
    getStatusSelf(row){
      switch (row.status) {
        case 0:
          return '有效';
        case 1:
          return '已过期';
        case 2:
          return '取消订阅';
        case 3:
          return '失败';
        case 4:
          return '待支付';
        case 5:
          return '待确认';
        case 6:
          return '已取消';
        case 7:
          return '已投诉';
        case 8:
          return '已拒绝';
      }
    },
     getStatus(status){
      let text  = '';
      switch (status) {
        /*<!--          Success("成功", 0),-->
        <!--          Fail_Risk("风控策略限制", 1),-->
        <!--          Fail_Risk_Stop("跟单功能已关闭", 101),-->
        <!--          Fail_Risk_MaxLots("交易量超限", 102),-->
        <!--          Fail_Risk_MaxNum("交易笔数超限", 103),-->
        <!--          Fail_Risk_Login_Stop("账户跟单功能已关闭", 104),-->
        <!--          Fail_Risk_Login_MaxLots("账户交易量超限", 105),-->
        <!--          Fail_Mt4("账户及风控设置", 2),-->
        <!--          Fail_TimeOut("网络超时", 3),-->
        <!--          Open_Waiting("待开仓", 4),-->
        <!--          Close_Waiting("待平仓", 5),-->
        <!--          Fail_Symbols("指数产品不能跟单", 6),-->
        <!--          Fail_User_Delete("用户已失效", 7),-->
        <!--          Fail_Other("其它原因", 99);-->*/
        case 0:
          text = '成功';
          break;
        case 1:
          text = '失败(风控策略)';
          break;
        case 2:
          text = '失败(账户及风控设置)';
          break;
        case 3:
          text = '失败(网路超时)';
          break;
        case 4:
          text = '失败(待开仓)';
          break;
        case 5:
          text = '失败(待平仓)';
          break;
        case 6:
          text = '失败(指数产品不能跟单)';
          break;
        case 7:
          text = '失败(用户已失效)';
          break;
        case 99:
          text = '失败（其它原因）';
          break;
        case 102:
          text = '失败（交易量超限）';
          break;
        case 103:
          text = '失败（交易笔数超限）';
          break;
        case 104:
          text = '失败（账户跟单功能已关闭）';
          break;
        case 105:
          text = '失败（账户交易量超限）';
          break;
      }
      return  text;
    },
    getType(row){
      // status
      if(row.status == 0){
        return '跟随平仓';
      }else if(row.status == 2){
        return row.unbind == 0 ? '跟随平仓': '手动平仓';
      }else {
        return '-'
      }
    },
    getPayChannel(row){
      switch (row.payChannel) {
        case 1:
          return '支付宝';
        case 2:
          return '微信';
        case 3:
          return '代币'
      }
    },
    getUsdtFlag(row){
      return row.payChannel == 3 && row.subscriptionType == 2;
    },
    priceHandle(row){
      if(row.subscriptionType == 2){
        this.$store.commit('setSubflowNumber',row.id);
        this.$router.push({name: 'settleLog'});
      }
    },
    getActive(row){
      return row.subscriptionType == 2;
    }
  }
}
</script>

<style lang="less" scoped>

  .price {
    &.active {
      color: #66b1ff;
    }
  }
</style>
