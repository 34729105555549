<template>
  <el-dialog :append-to-body="true" :visible.sync="dialog" :title="isHandle?'投诉详情':'流水详情'" width="820px">
      <!--表格渲染-->
      <el-table v-loading="loading" :data="subData" stripe style="width: 100%;">
        <el-table-column prop="operateName" label="操作记录"/>
        <el-table-column prop="" label="上传凭证" >
          <template slot-scope="scope">
            <img v-if="scope.row.order == 2 && scope.row.order !== 4" :src="scope.row.proof" alt="" width="100" height="100"  @click="bigBtn(scope.row)">
            <img v-if="scope.row.order == 4 && scope.row.order !== 2" :src="scope.row.proof[0].proofUrl" alt="" width="100" height="100" @click="bigBtn(scope.row)">
          </template>
        </el-table-column>
        <el-table-column prop="operateReason" label="操作原因"/>
        <el-table-column prop="operator" label="操作人" />
        <el-table-column prop="operateTime" label="操作时间">
          <template slot-scope="scope">
            <span>{{ parseTime(scope.row.operateTime) }}</span>
          </template>
        </el-table-column>
      </el-table>
    <div slot="footer" class="dialog-footer" v-if="isHandle">
      <el-button @click="dialog = false">取 消</el-button>
      <el-button type="danger" @click="refuse" v-if="checkPermission(['ADMIN','COMPLAINT_ALL','COMPLAINT_EDIT'])">拒 绝</el-button>
      <el-button type="primary" @click="submitPass" v-if="checkPermission(['ADMIN','COMPLAINT_ALL','COMPLAINT_EDIT'])">订 阅</el-button>
    </div>
    <div slot="footer" class="dialog-footer" v-if="!isHandle">
      <el-button type="primary" @click="dialog = false">关 闭</el-button>
    </div>

    <!-- 放大图片 -->
    <el-dialog :visible.sync="dialogOneImg" :append-to-body="true">
      <img width="100%" :src="ImageOneUrl" alt="">
    </el-dialog>
    <!-- 图片轮播 -->
    <el-dialog :append-to-body="true" :visible.sync="dialogArrImg" style="z-index: 1000;">
      <el-carousel  indicator-position="none" arrow="always">
        <el-carousel-item v-for="item in ImageArrUrl" :key="item.id">
          <el-row class="el-row-imgBg">
            <!-- <el-col :span="24" :style="{backgroundImage:'url('+item.proofUrl+')'}" class="el-col-imgBg" ></el-col> -->
            <img width="100%" :src="item.proofUrl" alt="">
          </el-row>
        </el-carousel-item>
      </el-carousel>
    </el-dialog>

  </el-dialog>
</template>

<script>
import { parseTime } from '@/utils/index'
import { Handle } from '@/api/community/srCommunitySubSerial.js'
import request from '@/utils/request'
import checkPermission from '@/utils/permission'
export default {
  props: {
    isHandle: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      loading: false,
      subData: [],
      complaintId: '',
      dialog: false,
      showProof: Boolean,
       // 图片放大
      dialogOneImg:false,
      ImageOneUrl:'',
      // 图片轮播
      dialogArrImg: false,
      ImageArrUrl: []
     }
  },
  methods: {
    checkPermission,
    parseTime,
    resetForm() {
      this.dialog = false;
    },
    // 图片放大
    bigBtn(row) {
      if(row.order ==2) {
        this.ImageOneUrl = row.proof;
        this.dialogOneImg = true;
      }
      if (row.order == 4) {
        this.ImageArrUrl = row.proof;
        this.dialogArrImg = true;
      }
    },
    // 图片上传
    handleSuccess(response){
        //上传成功要处理的事
        this.siteInfo.bannerBg=response.data.content[0].url;
    },
    // 拒绝
    refuse() {
      this.toChange();
       this.$prompt('请输入拒绝原因', '拒绝通过', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /\S/,
          inputErrorMessage: '请输入拒绝原因'
        }).then(({ value }) => {
          request({
              url: 'community/crm/srComplaint/'+ this.complaintId + "?rejectReason="+value,
              method: 'put'
          }).then(res => {
            this.sup_this.init()
            this.resetForm()
            this.$notify({
              title: res.message,
              type: 'success',
              duration: 2500
            })
            this.loading = false
            }).catch(err => {
              this.loading = false
          })
        }).catch(() => {
          console.log('取消订阅操作')
        });
    },
    // 订阅
    submitPass() {
      this.toChange();
      this.$confirm('是否确认建立双方订阅关系？', '订阅', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消'
        })
        .then(() => {
            Handle(this.complaintId).then(res => {
              this.resetForm()
              this.sup_this.init()
              this.$notify({
                title: res.message,
                type: 'success',
                duration: 2500
              })
              this.loading = false
            }).catch(err => {
              this.loading = false
            })
        })
        .catch(action => {
          console.log('取消订阅操作')
        });
    },
    toChange() {
      for (var i = 0; i < this.subData.length; i++) {
        if(this.subData[i].order == 4) {
          this.complaintId = this.subData[i].proof[0].complaintId
        }
      }
    }
  }
}
</script>

<style lang="less">
  .cashdialog {
    .title {
      background: #ccc;
      padding: 10px 20px;
      color: #333;
      font-size: 20px;
      font-weight: 800;
    }
    .info {
      margin: 10px;
      .el-col {
        padding: 15px 20px;
        .th {
          font-size: 16px;
          font-weight: 800;
          padding: 10px 10px;
        }
        .td {

        }
      }
    }
    .opinion {
      display: flex;
      align-items: center;
      .paylink {
         display: flex;
         height: 52px;
         align-items: flex-start;
      }
    }
  }
</style>
